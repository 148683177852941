import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GridContainer from "../components/GridContainer";
import { useEffect, useState } from "react";
import {
  getArtists,
  getArts,
  getFilteredData,
} from "../api/api";
import { modifydata, randomArtistData } from "../utils";
import Pagination from "../components/Pagination";
import NoData from "../components/NoData";

const ArtsContainer = () => {
  const [artistsData, setArtistsData] = useState();
  const [artist, setArtist] = useState([]);
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [paginationData, setPaginationData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await getArts();
    setPaginationData(data);
    const { data: artists } = await getArtists();
    setCount(artists?.count);
    setArtist(modifydata(artists?.results));
    setArtistsData(randomArtistData(data?.results));
  };

  const filteredData = async (value) => {
    const { data } = await getFilteredData(value);
    setPaginationData(data);
    setArtistsData(randomArtistData(data?.results));
  };

  return (
    <>
      <h1 className="header">Explore our different Arts</h1>
      <div className="cat_container">
        <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
          <InputLabel id="select-label">Select Filter</InputLabel>
          <Select
            name={filter}
            value={filter}
            label="Select Filter"
            onChange={(e) => {
              setFilter(e.target.value);
              filteredData(e.target.value);
            }}
            autoWidth
          >
            {artist?.map((item) => (
              <MenuItem
                sx={{ m: 1, minWidth: 230 }}
                key={item.label}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {artistsData?.length ? (
        <Grid container>
          {artistsData?.map((artist) => (
            <GridContainer
              data={artist}
              key={artist?.id}
              artistsData={artistsData}
            />
          ))}
        </Grid>
      ) : (
        <NoData/>
      )}

      <Pagination
        data={paginationData}
        setData={setArtistsData}
        setPagination={setPaginationData}
        count={count}
      />
    </>
  );
};

export default ArtsContainer;
