import Grid from "@mui/material/Grid";
import Arts from "./Arts";

const GridContainer = ({ data, artDetails }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} className="artists_card_section">
      <Arts data={data} artDetails={artDetails} />
    </Grid>
  );
};

export default GridContainer;
