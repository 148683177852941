import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { TbMessage } from "react-icons/tb";
import { contactForm } from "../constants";
import { Snackbar } from "@mui/material";

const Contact = () => {
  const form = useRef();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_7mpnwrm", "template_vt0is69", form.current, {
        publicKey: "WdeaHccX1sjvOegJZ",
      })
      .then(
        () => {
          setOpen(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <h1 className="header">Send Us an Enquiry</h1>
      <div className="contact container">
        <div className="contact_right_side">
          <form className="contact_form" ref={form} onSubmit={handleSubmit}>
            {contactForm.map((field) => (
              <div key={field.id} className="input_container">
                <label htmlFor="name">{field.name}</label>
                <div className="input_field">
                  {field.icon}
                  <input
                    type={field.type}
                    id={field.id}
                    name={field.name}
                    placeholder={field.placeholder}
                    required={field.required}
                  />
                </div>
              </div>
            ))}
            <div className="input_container">
              <label htmlFor="name">Messsage</label>
              <div className="input_field">
                <TbMessage />
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  placeholder="Type your message here"
                />
              </div>
            </div>
            <button type="submit" className="contact btn">
              SEND
            </button>
          </form>
        </div>
      </div>
      <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
            message="Enquiry sent"
          />;
    </>
  );
};

export default Contact;
