import axios from "axios";
import { BASE_URL } from "../constants";

// Get all Arts
export const getArts = () => {
  try {
    const data = axios.get(`${BASE_URL}/arts/`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Get Art Detail
export const getArtDetails = (id) => {
  try {
    let details = axios.get(`${BASE_URL}/arts/${id}/`);
    return details;
  } catch (error) {
    console.log(error);
  }
};

// Get Artist Details
export const getArtist = (id) => {
  try {
    let artistData = axios.get(`${BASE_URL}/authors/${id}/`);
    return artistData;
  } catch (error) {
    console.log(error);
  }
};

// Get Artist Data
export const getArtistData = (id) => {
  try {
    let artistData = axios.get(`${BASE_URL}/arts/?author_id=${id}`);
    return artistData;
  } catch (error) {
    console.log(error);
  }
};

// Get Filtered Data
export const getFilteredData = (value) => {
  try {
    let artistData = axios.get(`${BASE_URL}/arts/?author_name=${value}`);
    return artistData;
  } catch (error) {
    console.log(error);
  }
};

// Get Categories
export const getCategories = () => {
  try {
    let categories = axios.get(`${BASE_URL}/categories/`);
    return categories;
  } catch (error) {
    console.log(error);
  }
};

// Get Artists
export const getArtists = () => {
  try {
    let artists = axios.get(`${BASE_URL}/authors/`);
    return artists;
  } catch (error) {
    console.log(error);
  }
};

export const getArtsCount = () => {
  try {
    const data = axios.get(`${BASE_URL}/arts/?page_size=1000000`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const pagination = (url) => {
  try {
    const details = axios.get(url);
    return details;
  } catch (error) {
    console.log(error);
  }
};
