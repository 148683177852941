import { LuUser2, LuPhone } from "react-icons/lu";
import { AiOutlineMail } from "react-icons/ai";

export const year = new Date().getFullYear();

export const PHONE_NUMBER = 9967587615;
export const WA_URL = "https://api.whatsapp.com/send?phone="

export const BASE_URL = 'https://yadneshwar.com/api';

export const contactForm = [
  {
    type: "text",
    id: "name",
    name: "name",
    placeholder: "Type your name",
    required: true,
    icon: <LuUser2 />,
  },
  {
    type: "email",
    id: "email",
    name: "email",
    placeholder: "Type your email",
    required: true,
    icon: <AiOutlineMail />,
  },
  {
    type: "phone",
    id: "mobile",
    name: "mobile",
    placeholder: "Type your mobile no",
    required: true,
    icon: <LuPhone />,
  },
];

