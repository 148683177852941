import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridContainer from "./GridContainer";
import { Grid } from "@mui/material";
import { getArtist, getArtistData } from "../api/api";
import Pagination from "./Pagination";
import { randomArtistData } from "../utils";
import NoData from "./NoData";

const Artist = () => {
  const { id } = useParams();
  const [artistData, setArtistData] = useState();
  const [artistDetails, setArtistDetails] = useState({});
  const [paginationData, setPaginationData] = useState();

  useEffect(() => {
    fetchArtistData();
  }, []);

  const fetchArtistData = async () => {
    const { data } = await getArtist(id);
    const { data: artData } = await getArtistData(id);
    setArtistData(randomArtistData(artData?.results));
    setArtistDetails(data);
    setPaginationData(artData);
  };

  console.log(artistData);

  return (
    <>
      <div className="artist_container">
        <h1 className="header">{artistDetails?.name}</h1>
        <div className="artist_details">
          <div className="left_side">
            <figure>
              <img src={artistDetails?.photo} alt="" />
            </figure>
          </div>
          <div className="right_side">
            <h2>{artistDetails?.description}</h2>
          </div>
        </div>
      </div>

      {artistData?.length ? (
        <Grid container>
          {artistData?.map((artist) => (
            <GridContainer data={artist} key={artist?.id} />
          ))}
        </Grid>
      ) : (
        <NoData />
      )}
      <Pagination
        data={paginationData}
        setData={setArtistData}
        setPagination={setPaginationData}
      />
    </>
  );
};

export default Artist;
