import { pagination } from "../api/api";
import { randomArtistData } from "../utils";

const Pagination = ({ data, setData, setPagination }) => {
  const handlePagination = async (url) => {
    const { data } = await pagination(url);
    setData(randomArtistData(data?.results));
    setPagination(data)
  };

  console.log(data)

  return (
    <div className="pagination">
      {data?.previous && (
        <button
          className="btn"
          onClick={() => handlePagination(data?.previous)}
        >
          Previous
        </button>
      )}
      {data?.count > 0 && <h4>Total : {data?.count}</h4>}
      {data?.next && (
        <button className="btn" onClick={() => handlePagination(data?.next)}>
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;
