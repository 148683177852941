import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { PHONE_NUMBER, WA_URL } from "./constants";

export const openWhatsApp = (details) => {
  const { artId, title, category, measurement, price, author } = details;

  const message = `Hello, I want to inquire about below artwork,
  Art Id: ${artId}
  Name: ${title}
  Price: ${price}
  Title: ${category?.name} by ${author?.name}
  size: ${measurement}`;

  const url = `${WA_URL}${PHONE_NUMBER}&text=${encodeURIComponent(message)}`;
  window.open(url, "_blank");
};

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const modifyCategories = (categories) => {
  return categories?.map((item) => ({
    label: item?.name,
    value: item.name,
  }));
};

export const modifydata = (data) => {
  return data
    ?.map((item) => item.name)
    .map((artist) => ({ label: artist, value: artist }));
};

export const randomArtistData = (artistsData) => {
  console.log(artistsData);
  for (let i = artistsData.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [artistsData[i], artistsData[j]] = [artistsData[j], artistsData[i]];
  }
  return artistsData;
};

export const randomPageNo = (count) => {
  const randomNo = Math.floor(Math.random() * count) + 1;
  const randomPg = randomNo / 10;
  return Math.ceil(randomPg);
};
