import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Artist from "./components/Artist";
import ArtsContainer from "./containers/ArtsContainer";
import ArtDetailsContainer from "./containers/ArtDetailsContainer";
import NotFound from "./components/NotFound";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/arts" element={<ArtsContainer />} />
          <Route path="/art/:id" element={<ArtDetailsContainer />} />
          <Route path="/artists/:id" element={<Artist />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
