import React from 'react'
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='not_found'>
        <h1>404 Error</h1>
        <h1>Page Not Found</h1>
        <NavLink to="/" >Back to Home Page</NavLink>
    </div>
  )
}

export default NotFound;