import { NavLink } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { openWhatsApp } from "../utils";

const Arts = ({ data }) => {
  const { id, image, price, title, author } = data;

  return (
    <div className="artists_card">
      <NavLink to={`/art/${id}`} id="art">
        <img src={image} alt="" />
      </NavLink>
      <div className="artName">Title : {title}</div>
      <div className="artist_price">
        <span>
          Asking Price : Rs. {price}
          {price ? "/-" : "NA"}{" "}
        </span>
        <NavLink to="/contact" className="buy btn">
          Buy
        </NavLink>
      </div>
      <NavLink to={`/artists/${author?.id}`}>
        {author?.name?.split("-")[0]}
      </NavLink>
      <button
        type="button"
        className="btn whatsApp"
        onClick={() => openWhatsApp(data)}
      >
        <BsWhatsapp />
      </button>
    </div>
  );
};

export default Arts;
