import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import { openWhatsApp } from "../utils";
import { getArtDetails } from "../api/api";

const ArtDetails = () => {
  const { id } = useParams();
  const [artDetails, setArtDetails] = useState();

  useEffect(() => {
    getDetails(id);
  }, [id]);

  const getDetails = async (id) => {
    const { data } = await getArtDetails(id);
    setArtDetails(data);
  };

  return (
    <>
      <h1 className="header">{artDetails?.artId}</h1>
      <div className="artDetails">
        <figure className="art">
          <img src={artDetails?.image} />
        </figure>

        <div className="details">
          <h3>{artDetails?.title}</h3>
          <NavLink to={`/artists/${artDetails?.author?.id}`} className="creator">
            by<h3> {artDetails?.author?.name}</h3>
          </NavLink>
          <span>{artDetails?.address}</span>
          <span>{artDetails?.category?.name}</span>
          <span>{artDetails?.size}</span>
          {artDetails?.price && <h3>Rs. {artDetails?.price}</h3>}
          <h3>{artDetails?.status}</h3>
          <h3>{artDetails?.note}</h3>
          <NavLink to="/contact" className="buy btn">
            Buy this artwork
          </NavLink>
          <button
            type="button"
            className="btn whatsApp"
            onClick={() => openWhatsApp(artDetails)}
          >
            <BsWhatsapp />
          </button>
        </div>
      </div>
    </>
  );
};

export default ArtDetails;
